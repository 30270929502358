<template>
  <div class="calendar-container mt-2">
    <!-- Left Sidebar with Automated Height Scrollable List of Properties -->
    <div class="property-sidebar">
      <ul class="property-list">
        <li
            v-for="(property, id) in properties"
            :key="id"
            @click="selectProperty(id)"
            :class="{ active: selectedProperty === id }"
        >
          {{ property.label }}
        </li>
      </ul>
    </div>

    <!-- Main Calendar Component -->
    <div v-if="propertyId!=null" class="calendar-main">
      <calendar-rent  :property-id="propertyId" :key="propertyId"/>
    </div>
  </div>
</template>
<script>
import CalendarRent from "@/views/calendar/CalendarRent";
import {mapActions} from "vuex";


export default {
  name: "calendarRentSite",
  components: {
    CalendarRent
  },

  data() {
    return {
      propertyId: null,
      selectedProperty: null, // Selected property ID
      properties: null,
    }
  },
  created() {
    console.log('calendar site created getting real estates')
    this.getRealEstates()

  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions('realEstates', {_getRealEstates: 'getAllProperties'}),

    getRealEstates() {
      this._getRealEstates()
          .then((ans) => {
            console.log('Real estates got from db:', ans['RealEstates']);
            // Assuming ans['RealEstates'] is an array of properties with an 'id' and 'label' property
            // Convert the array into an object with 'id' as keys and 'label' as values
            this.properties = ans['RealEstates'].reduce((result, property) => {
              result[property.id] = { label: property.address + ', ' + property.apt + ', ' + property.zip  + ' ' + property.city };
              return result;
            }, {});

            const propertyIds = Object.keys(this.properties);
            if (propertyIds.length > 0) {
              this.propertyId = propertyIds[0];
            }
          })
          .catch(() => console.log('Error getting real estates from db'));
    },
    selectProperty(id) {
      // Set the selected property ID and update the property ID
      this.selectedProperty = id;
      this.propertyId = id; // You can adjust it based on your logic
    },
  }

}
</script>

<style scoped>
/* Styles for the calendar container */
.calendar-container {
  display: flex;
}

/* Styles for the property sidebar (left side) */
.property-sidebar {
  flex: 0 0 auto; /* Allow sidebar to have a fixed width */
  width: 200px; /* Adjust the width as needed */
  background-color: #f0f0f0; /* Add a background color */
  padding: 10px; /* Add padding as needed */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Add a shadow for separation */
}

/* Styles for the scrollable list of properties */
.property-list {
  list-style: none;
  padding: 0;
  max-height: calc(100vh - 40px); /* Calculate the maximum height based on viewport height and padding */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Styles for individual property items */
.property-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  font-family: Arial, sans-serif; /* Set a nice font */
  border-bottom: 1px solid #ccc; /* Add a border at the bottom of each item */
}

/* Highlight the selected property */
.property-list li.active {
  background-color: #c2aa8e; /* Highlight color for the selected property */
  color: #fff;
  font-weight: bold;
}

/* Styles for the main calendar content (right side) */
.calendar-main {
  flex: 1; /* Allow main content to take up remaining space */
  padding: 10px; /* Add padding as needed */
}

</style>
